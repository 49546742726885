import React, { useState, useEffect } from 'react';
import { connect } from './socket';

import RenderobjFinal from './Avatar/renderobjsfinal';
import GetGender from './Avatar/GetGender';

import RenderObj from './Avatar/RenderOBJ';
import DetectPhone from './Avatar/DetectPhone';



import MobileGetGender from './Avatar/Mobile/MobileGetGender';
import MobileGetSpesifications from './Avatar/Mobile/MobileGetSpesifications';
import MobileAvatarWithCamera from './Avatar/Mobile/MobileAvatarWithCamera';
import MobileSelectCam from './Avatar/Mobile/MobileSelectCam';
import MobileRenderObj from './Avatar/Mobile/MobileRenderOBJ';
import MobileAciklama from './Avatar/Mobile/MobileAciklama';
import MobileAvatarOrientation from './Avatar/Mobile/MobileAvatarOrientation';


import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
    Switch,
    Route,
    useHistory
  } from "react-router-dom";
  
const Routes = () => {
    const [step, setStep] = useState('');
    const [userSex, setuserSex] = useState('');
    const [userKey, setUserKey] = useState('');
    const [brand, setBrand] = useState('');
    const [boy, setBoy] = useState("");
    const [kilo, setKilo] = useState("");
    const [results, setResults] = useState("");
    const [display, setDisplay] = useState("flex");
    const [whichCam, setWhichCam] = useState({
        facingMode: "user"
      }
  );
    const [useCam, setUseCam] = useState(null);
    //const userKey = Math.floor(Math.random() * 100);
    let history = useHistory();

    useEffect(() => {
		if(userKey) {
			const socket = connect(userKey);
            
			if(socket) {
                socket.emit('sendResults', { userKey, result: "Results" });
				console.log(results)
				
			}
		}
    }, [brand, userKey, results]);

    return (
        <>
         <Route render={({location}) => (
            <TransitionGroup className="container">
                <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={1000}
                                    >
                    <Switch location={location}>
                        
             
                        
                        <Route path="/render">
                            <RenderObj userSex={userSex} step={step} results={results} kilo={kilo} boy={boy} setDisplay={setDisplay} display={display}  />
                        </Route>
                        <Route path="/DetectPhone">
                            <DetectPhone />
                        </Route>
                        <Route path="/withClothes">
                            <RenderobjFinal />
                        </Route>
                        <Route path="/mobilegetGender">
                            <MobileGetGender  userSex={userSex} step={step} setuserSex={setuserSex} setStep={setStep} setUseCam={setUseCam} setUserKey={setUserKey} setBrand={setBrand}/>

                        </Route>
                        <Route path="/mobilegetSpesifications" exact>
                            <MobileGetSpesifications userSex={userSex} step={step} boy={boy} kilo={kilo} setBoy={setBoy} setKilo={setKilo} />

                        </Route>
                     
                        <Route path="/mobilechooseCam" exact>
                            <MobileSelectCam setWhichCam={setWhichCam} />

                        </Route>
                        <Route path="/mobileshowCam"  exact>
                            <MobileAvatarWithCamera userSex={userSex} step={step} kilo={kilo} boy={boy}  setResults={setResults} useCam={useCam} whichCam={whichCam}  userKey={userKey} />

                        </Route>
                        <Route path="/mobilerender"  exact>
                            <MobileRenderObj  userSex={userSex} step={step} results={results} kilo={kilo} boy={boy} setDisplay={setDisplay} display={display}  />

                        </Route>
                        <Route path='/mobileDescription' exact>
                            <MobileAciklama />
                        </Route>
                        <Route path="/mobilegetSensor" exact>
                            <MobileAvatarOrientation />

                        </Route>
                        <Route path="/">
                            <GetGender userSex={userSex} step={step} setuserSex={setuserSex} setStep={setStep} setUseCam={setUseCam} useCam={useCam} setUserKey={setUserKey} setBrand={setBrand}/>
                        </Route>


                    </Switch>
                    </CSSTransition>
            </TransitionGroup>
         )} />         
        </>


    );
}  


export default Routes;
