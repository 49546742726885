/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { useHistory } from "react-router-dom";



const GetGender = ({t}) => {
    const history = useHistory();

   
    return (
        <div className="landing landing-nojustify landingmobile">


    <img src="resize.gif"  alt="sizemelogo" style={{marginBottom: "3rem"}} width="250px"/>
            <div style={{width: "40rem", textAlign: "center"}} >
                <span className="aciklamafirst">{t("MobileAcıklamaFirst")}</span>
                <br></br>
                <br></br>
                <span className="aciklamafirst">{t("MobileAcıklamaSecond")}</span>
                <br></br>
                <br></br>
                <span className="aciklamafirst">{t("MobileAcıklamaThird")}</span>
                <br></br>
                <br></br>
            </div>
            <button type="submit"  className="btn btn-shadow btn-blue btn-smaller" style={{padding: "2rem 2rem"}} onClick={() =>  history.push("/mobilechooseCam")}>{t("Continue")}</button>
     
        </div>
    )
}

export default withNamespaces()(GetGender);