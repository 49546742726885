/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next';
import { useHistory } from "react-router-dom";

const ChooseCam = ({ setWhichCam, t }) => {
    let history = useHistory();


    return (
        <div className="landing landing-nojustify landingmobile">


<img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg"  alt="sizemelogo" style={{marginBottom: "3rem"}} width="250px"/>
          
            <div className="div__flex-buttons">
                <img className="getgenderil" onClick={() => {
                    setWhichCam({
                        facingMode: { exact: "environment" }
                      })
                      history.push("/mobileshowCam")
       
                }} src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/2.png"/>
                <img className="getgenderil"  onClick={() => {
                    setWhichCam({
                        facingMode: "user"
                      })
                      history.push("/mobileshowCam")
            
            }} src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/1.png"/>
            </div>

            <div style={{width: "40rem", textAlign: "center"}} >
                <span className="aciklamafirst">{t("ChooseCam")}</span>

            </div>
     
        </div>
    )
}

export default withNamespaces()(ChooseCam);