/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect }  from 'react';
import useState from 'react-usestateref';
import { useHistory } from "react-router-dom";
import Alert from '../GeneralComponents/Alert';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import Webcam from "react-webcam";
import lottie from 'lottie-web';

import ReactAudioPlayer from 'react-audio-player';
import { WebGLInfo } from 'three';
import AlertD from '../GeneralComponents/alert2'
import { withNamespaces } from 'react-i18next';
import { useDeviceOrientation }  from './useDeviceOrientation';


const AvatarEntry = ({userSex, step, kilo, boy, setResults, useCam, whichCam, t}) => {

    const { orientation, requestAccess, revokeAccess, mistake } = useDeviceOrientation();


   
    const onToggle = (toggleState) => {
        const result = toggleState ? requestAccess() : revokeAccess();
      };
    let history = useHistory();

    const [takingPhoto, setTakingPhoto] = React.useState(false)

    const [isAngleTrue, setIsAngleTrue, angleRef] = useState("#9e0505")

    const [x , setX, XRef] = useState("red")
    const [counter, setCounter, counterRef] = useState(0);
      useEffect(() => {
        onToggle(true);

      }, [])

    useEffect(() => {
        
        if (orientation) {   
          
        if (orientation.beta < 94) {

            if (orientation.beta > 80) {
                setX("green")
              
            } else {
                if (takingPhoto === false) { 
                    setX("red")
                  
           
   
                    setCounter(0);

                }
            }
            
            
        } else {
            /* if (takingPhoto === true) {
                startCountdown(false)
            } */
            if (takingPhoto === false) { 
                setX("red")

                setCounter(0);
            }
        }
    }

    }, [orientation])
    
      
 




    return (
    
        <div className="landing landing-nojustify cameralan" style={{padding: 0, justifyContent: "unset", alignItems: "unset"}}>
  
      
                <div style={{padding: "0 !important"}}>
                    <div id="allpagewhite">
                        <img src='bar.svg' id="bar" alt="bar" />
                        {orientation &&  <img src="barupdown.svg" id="barupdown" style={{backgroundColor: x, transform: `translateY(
      calc(${orientation.beta} * calc(30vh / 150))
    )`}} alt="barslider" />}
                        
                        <span style={{zIndex: 99999999999999, position: "absolute", fontSize: "1rem", top: "5rem"}}>{XRef.current}</span>
                    </div>
            
               
                </div>
                
                            

            
        </div>
    );
};

export default withNamespaces()(AvatarEntry);
