/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Formik, Form } from 'formik';
import { withNamespaces } from 'react-i18next';

const Footer = ({t}) => {

    return (
        <div  className="footermobile" style={{width: "unset", height: "unset"}}>
        
            <div className="avatar__wrapper">
                <div className="avatar avatarshowon" id="showmeonweb" style={{margin: "0 !important"}}>
                 {/*    <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg"  alt="sizemelogo" style={{marginBottom: "2rem"}}/> */}
                 
                   
           
                        <Formik >
                            <Form className="avatar__inputs" style={{gridGap: "1rem"}} >
                            <div className="col-md-3">
                <div className="footer-title">
                    <h6 className="footer-title-h">{t('Follow')}</h6>
                    <ul>
                    <li>
                        <a href="https://www.linkedin.com/company/sizeandme/" target="_blank">Linkedin</a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/sizeandme/" target="_blank">Instagram</a>
                    </li>
                    <li>
                        <a href="https://twitter.com/sizeandme" target="_blank">Twitter</a>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col-md-3">
                <div className="footer-title">
                    <h6 className="footer-title-h">{t("AboutUs")}</h6>
                    <ul className="footer-text">
                    <li>
                       
                            <a href="https://sizeandme.com/team">{t('AboutSize&Me')}</a>
                   
                    </li>
                    <li>
                       
                        <a  href="https://sizeandme.com/contactus">{t('Contact')}</a>
            
                    </li>
                    <li>
            
                            <a  href="https://sizeandme.com/press">{t('InPress')}</a>
                       
                    </li>
                    <li>
            
                            <a   href="https://sizeandme.com/stylists">{t('Partners')}</a>
                    
                    </li>
                    <li>
                        <a href="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/6.%2BSize%26me%2BKVKK%2BPOL%C4%B0T%C4%B0KASI.pdf">
                        {t('KVKK')}
                        </a>
                    </li>
                    <li>
                        <a href="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/3%2B.%2B%C3%87erez%2BPolitikas%C4%B1.pdf">
                        {t('Cookies')}
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col-md-3">
                <div className="footer-title">
                    <h6 className="footer-title-h">{t("Solutions")}</h6>
                    <ul className="footer-text">
                
                    <li>
                        <a  data-scroll href="https://sizeandme.com/#solutions">{t("Learn")}</a>
                    </li>
                   
                    <li>
                        <a  data-scroll href="https://sizeandme.com/#solutions">{t("CreateAvatar")}</a>
                    </li>
                    <li>
                        <a  data-scroll href="https://sizeandme.com/#solutions">{t("DressAvatar")}</a>
                    </li>
                    
                    </ul>
                </div>
                </div>
                            
                      
                                <div className="col-md-4 footeradres">
                                <div className="footer-title">
                                    <h6 className="footer-title-h">{t("AdressTitle")}</h6>
                                    <p><span>{t("Adress")}</span>
                                    <br></br>
                                    <span>  Kolektif House</span>
                                    <br></br>
                                    <span>  Levent, Şişli / İstanbul</span>
                                    <br></br>
                                    <span>  info@sizeandme.com</span>
                                
                                
                                </p>
                                </div>
                            </div>
                        
                       
                               

                            </Form>
                            
                        </Formik>
                    
                        
                        
               
                </div>
            </div>
            
        </div>
    );
};

export default withNamespaces()(Footer);