import React from 'react'
import i18n from '../../i18n';

const LanguageSelector = ({final}) => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      }
    return (
        <div className={`languageselect ${final}`} style={{zIndex: 99999}}>
        <div className="languageselector" onClick={() => changeLanguage("en")}>
            <div className="languageselector__flex">
                <img className="languageselector__flag" src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/United-kingdom_flag_icon_round.svg" width="35px"/>
            </div>
            <div className="languageselector__flex">
                <span className="languageselector__text">
                    English
                </span>
            </div>
        </div>
        <div className="languageselector"  onClick={() => changeLanguage("tr")}>
            <div className="languageselector__flex">
                <img className="languageselector__flag" src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/turkey.svg" width="35px"/>
            </div>
            <div className="languageselector__flex">
                <span className="languageselector__text">
                    Turkish
                </span>
            </div>
        </div>
    </div>
    )
}

export default LanguageSelector;
