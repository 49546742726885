/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Formik, Form } from 'formik';
import { withNamespaces } from 'react-i18next';
import Switch from "react-switch";
import i18n from '../i18n';
import LanguageSelector from './GeneralComponents/LanguageSelector';


const AvatarResult = ({userSex, t, results, boy, kilo, display, setDisplay}) => {
    const [neck, setNeck] = useState("");
    const [chest, setChest] = useState("");
    const [hip, setHip] = useState("");
    const [arm, setArm] = useState("");
    const [leg, setLeg] = useState("");
    const [waist, setWaist] = useState("")    
   
    const [isMetric, setIsMetric] = useState(true)

    const conversionHeight = (centi) => {
        var feet = Math.floor(0.0328 * centi);
        var inch = Math.floor(0.3937 * centi - feet * 12);
        return `${feet} ft ${inch} in`
    }
    const conversionWeight = (kg) => {
        var lbs = Math.floor(kg / 0.45359237 );
        return `${lbs} lbs`
    }

    let history = useHistory();
     useEffect(() => {
         if (results) {
             setNeck(results.neck)
             setChest(results.chest)
             setHip(results.waist)
             setWaist(results.hip)
             setLeg(results.legSize)
             setArm(results.armSize)

         }
        //if (step === '') history.push('/landing');
     /*    let neck =  (new URLSearchParams(window.location.search)).get("neck");
        setNeck(neck);
        let chest =  (new URLSearchParams(window.location.search)).get("chest");
        setChest(chest);
        let hip =  (new URLSearchParams(window.location.search)).get("hip");
        setHip(hip);
        let arm =  (new URLSearchParams(window.location.search)).get("arm");
        setArm(arm);
        let leg =  (new URLSearchParams(window.location.search)).get("leg");
        setLeg(leg); */
    }, [results]); 
/*     const onSubmit = () => {
        window.location.href = `https://sizeandme.com/app/bodysize?neck=${neck}&chest=${chest}&hip=${hip}&gender=${userSex}`;

    }; */
    return (
        <div /* className="overlayleft" */ style={{width: "unset", height: "unset"}}>
  
        
            <div className="avatar__wrapper">
                
                <div className="avatar avatarshowon" id="showmeonweb" style={{margin: "0 !important"}}>
                <LanguageSelector final='last'/>
                <div className="metricchange posabsmobil">
                       <div className="metricchange__flex">
                           <span className="metricchange--text-large">
                                Metric System
                           </span>
                           <span className="metricchange--text-small">
                                CM/KG
                           </span>
                       </div>
                       <Switch onChange={() => setIsMetric(!isMetric)} checked={isMetric} onColor='#5B31A1' offColor="#b170f3"  uncheckedIcon={false}
            checkedIcon={false}   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                       <div className="metricchange__flex">
                       <span className="metricchange--text-large">
                                Imperial System
                           </span>
                           <span className="metricchange--text-small">
                                IN/LB
                           </span>
                       </div>
                   </div>
                 {/*    <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg"  alt="sizemelogo" style={{marginBottom: "2rem"}}/> */}
                 <h3 className="heading-2 heading-mobile" style={{marginBottom: "1.5rem"}}>{t('Measurement')}</h3>
                        <Formik>
                            <Form className="avatar__inputs" style={{gridGap: "1rem"}} >
                            
                            <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="boy" className="avatar__input--label" >
                                        {t("Height")}
                                    </label>
                                    { isMetric ?
                                        <input id="boy" name="boy" className="avatarinputslastpage inputlastpage" value={boy} readOnly/>
                                    :
                                        <input id="boy" name="boy" className="avatarinputslastpage inputlastpage" value={conversionHeight(boy)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="kilo" className="avatar__input--label" >
                                    {t("Weight")}
                                    </label>
                                    { isMetric ?
                                        <input id="kilo" name="kilo" className="avatarinputslastpage inputlastpage" value={kilo} readOnly/>
                                    :
                                        <input id="kilo" name="kilo" className="avatarinputslastpage inputlastpage" value={conversionWeight(kilo)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="neck" className="avatar__input--label" >
                                    {t("Neck")}
                                    </label>
                                    { isMetric ?
                                        <input id="neck" name="neck" className="avatarinputslastpage inputlastpage" value={neck} readOnly/>
                                    :
                                        <input id="neck" name="neck" className="avatarinputslastpage inputlastpage" value={conversionHeight(neck)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="chest" className="avatar__input--label" >
                                    {t("Chest")}
                                    </label>
                                    {isMetric ?
                                        <input id="chest" name="chest" className="avatarinputslastpage inputlastpage" value={chest} readOnly/>
                                    :
                                        <input id="chest" name="chest" className="avatarinputslastpage inputlastpage" value={conversionHeight(chest)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="hip" className="avatar__input--label" >
                                    {t("Waist")}
                                    </label>
                                    {isMetric ?
                                        <input id="hip" name="hip" className="avatarinputslastpage inputlastpage" value={hip} readOnly/>
                                    :
                                        <input id="hip" name="hip" className="avatarinputslastpage inputlastpage" value={conversionHeight(hip)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="gobek" className="avatar__input--label" >
                                    {t("Belly")}
                                    </label>
                                    {isMetric ?
                                        <input id="hip" name="hip" className="avatarinputslastpage inputlastpage" value={waist} readOnly/>
                                    :
                                        <input id="hip" name="hip" className="avatarinputslastpage inputlastpage" value={conversionHeight(waist)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="arm" className="avatar__input--label" >
                                    {t("Arm")}
                                    </label>
                                    {isMetric ?
                                        <input id="arm" name="arm" className="avatarinputslastpage inputlastpage" value={arm} readOnly/>
                                    :
                                        <input id="arm" name="arm" className="avatarinputslastpage inputlastpage" value={conversionHeight(arm)} readOnly/>
                                    }
                                </div>
                                <div className="avatar__inputs--control inputlastpage" style={{margin: "0.5rem 0"}}>
                                    <label htmlFor="leg" className="avatar__input--label" >
                                    {t("Leg")}
                                    </label>
                                    {isMetric ?
                                        <input id="leg" name="leg" className="avatarinputslastpage inputlastpage" value={leg} readOnly/>
                                    :
                                        <input id="leg" name="leg" className="avatarinputslastpage inputlastpage" value={conversionHeight(leg)} readOnly/>
                                    }
                                </div>
                             {/*    <a href="https://sizeandme.com/app/withClothes"  style={{textTransform: "none"}} id="mobilelinkgiydirilmis" className="btn btn-shadow btn-blue btn-smaller" >Giydirilmiş Model</a> */}
                                
                            {/*     <a href="https://sizeandme.com/app/photo-step1" style={{justifySelf: "center"}}>
                                    <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/bottomarrow.svg" alt="backbutton" style={{width: "3rem"}}/>
                                </a>
                                */}
                               

                            </Form>
                            
                        </Formik>
                        <Dynamicbody arml={arm} lowerb={hip} chest={chest} shoulder={neck} leg={leg} waist={waist}/>
                        
                        
               
                </div>
            </div>
            
        </div>
    );
};



const Dynamicbody = ({shoulder, chest, lowerb, arml, leg, waist}) => {

    return (
        <div className="objcontainer" style={{marginTop: "1rem"}}>
            <p className="landing__userbody-length">{shoulder}</p>
            <p className="landing__userbody-length landing__userbody-length-2">{chest}</p>
            <p className="landing__userbody-length landing__userbody-length-3">{lowerb}</p>
            <p className="landing__userbody-length landing__userbody-length-6">{waist}</p>
            <p className="landing__userbody-length landing__userbody-length-4">{arml}</p>
            <p className="landing__userbody-length landing__userbody-length-5">{leg}</p>
            <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/avatarsizes.svg" alt="sizes" className="landing__userbody-length-avatar" />

        </div>
        
    );

};
export default withNamespaces()(AvatarResult);
