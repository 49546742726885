import React from 'react'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
import { withNamespaces } from 'react-i18next';

const DetectPhone = ({t}) => {
    console.log(isMobile)
    return (
        <div>
            <span>{t("PhoneDetected")}</span>
            <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/fasdadsf%C3%A7.svg" width="150px" alt="qrcode" />
            <button>{t("IWantContinue")}</button>


            
        </div>
    )
}


export default withNamespaces()(DetectPhone);