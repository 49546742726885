
/* eslint-disable no-unused-vars */
import React, { useEffect, useState }  from 'react';

import { useHistory } from "react-router-dom";
import { Formik, Form } from 'formik';
import Alert from '../GeneralComponents/Alert';
import { CSSTransition } from 'react-transition-group';
import { withNamespaces } from 'react-i18next';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Switch from "react-switch";
import LanguageSelector from '.././GeneralComponents/LanguageSelector';
import i18n from '../../i18n';


const GetSpesificiations = ({kilo, boy, setKilo, setBoy, step, t}) => {
    let history = useHistory();

    const [isMetric, setIsMetric] = useState(true)
    let metricHeight = []
    let imperialHeight = []
    let metricWeight = []
    let imperialWeight = []

    const [showMessage, setShowMessage] = useState(false);
    const [errortext, setErrortext] = useState(null);
    /* navigator.mediaDevices.getUserMedia({ audio: true, video: true }) */

    const getMetricHeight = (start, end) =>  {
      for(let i = start; i<=end; i++)
      {
        metricHeight.push({label: `${i} cm `, value: i})
      }
  }
  const getImperialHeight = () =>  {
      for(let i = 4; i<=7; i++)
      {
          for(let j = 0; j<12 ; j++){
              if(i === 4)
              j = 11
              if(i === 7 && j === 3)
              break
              imperialHeight.push({label: `${i} ft ${j} in`, value: Math.floor(i * 30.48 + j * 2.54) })
          }     
      }
  }
  const getMetricWeight = (start, end) =>  {
      for(let i = start; i<=end; i++)
      {
          metricWeight.push({label: `${i} kg `, value: i})
      }
  }
  const getImperialWeight = (start, end) =>  {
      for(let i = start; i<=end; i++)
      {
          imperialWeight.push({label: `${i} lbs`, value: Math.floor(i * 0.45359237) })
      }     
  }
  const conversionHeight = (centi) => {
    var feet = Math.floor(0.0328 * centi);
    var inch = Math.floor(0.3937 * centi - feet * 12);
    return `${feet} ft ${inch} in`
  }
  const conversionWeight = (kg) => {
    var lbs = Math.floor(kg / 0.45359237 );
    return `${lbs} lbs`
  }


     useEffect(() => {
        if (step === '') history.push('/');
        getMetricHeight(150, 220)
        getImperialHeight()
        getMetricWeight(33, 160)
        getImperialWeight(67, 353)
    }, [getImperialHeight, getImperialWeight, getMetricHeight, getMetricWeight, history, step]); 
        

    function handleOrientation(event) {
    const alpha = event.alpha;
    const beta = event.beta;
    const gamma = event.gamma;
    // Do stuff...
    }
    
    function onClick() {
   
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
          // Handle iOS 13+ devices.
          DeviceMotionEvent.requestPermission()
            .then((state) => {
              if (state === 'granted') {
                window.addEventListener('devicemotion', handleOrientation);
                history.push(`/mobileDescription`);
              } else {
                console.error('Request to access the orientation was rejected');
              }
            })
            .catch(console.error);
        } else {
          // Handle regular non iOS 13+ devices.
          window.addEventListener('devicemotion', handleOrientation);
           history.push(`/mobileDescription`);
        }
      }
      function onClickDos() {
   
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
          // Handle iOS 13+ devices.
          DeviceMotionEvent.requestPermission()
            .then((state) => {
              if (state === 'granted') {
                window.addEventListener('devicemotion', handleOrientation);
                history.push(`/mobilegetSensor`);
              } else {
                console.error('Request to access the orientation was rejected');
              }
            })
            .catch(console.error);
        } else {
          // Handle regular non iOS 13+ devices.
          window.addEventListener('devicemotion', handleOrientation);
           history.push(`/mobilegetSensor`);
        }
      }

    return (
        <div className="landing landing-nojustify landingmobile">
      

        
                    <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg"  alt="sizemelogo" style={{marginBottom: "2rem"}} width="250px"/>
                   {/*  <h3 className="heading-2 heading-mobile" style={{marginBottom: "2rem"}}></h3> */}
                   <div className="metricchange">
                       <div className="metricchange__flex">
                           <span className="metricchange--text-large">
                                Metric System
                           </span>
                           <span className="metricchange--text-small">
                                CM/KG
                           </span>
                       </div>
                       <Switch onChange={() => setIsMetric(!isMetric)} checked={isMetric} onColor='#5B31A1' offColor="#b170f3"  uncheckedIcon={false}
            checkedIcon={false}   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                       <div className="metricchange__flex">
                       <span className="metricchange--text-large">
                                Imperial System
                           </span>
                           <span className="metricchange--text-small">
                                IN/LB
                           </span>
                       </div>
                   </div>
                  
                        <Formik initialValues={{}} onSubmit={async (values) => { 
                            try {
                                //const result = await axios.post("/body");
            
                               
                                //history.push(`/photo-step2?neck=${result.neckSize}&hip=${result.hipSize}&chest=${result.pecSize}&arm=${result.armSize}&leg=${result.legSize}`)

                            } catch (expection) {
                        
                                setErrortext(t("ServerError"));
                                setShowMessage(true);

                            }
                         }}>
                            <Form className="avatar__inputs" >
                                <div className="avatar__inputs--control adct" style={{margin: "2rem 0"}}>
                                    <label htmlFor="boy" className="avatar__input--label" >
                                    {t('Height')}
                                    </label>
                                    {/* <input id="boy" name="boy" type="number" minLength="2" maxLength="3" required className="avatar__input--input" value={boy} onChange={(e) => setBoy(e.target.value)}/> */}
                                    {isMetric ?
                                        <Dropdown 
                                            options={metricHeight} 
                                            onChange={(value) => setBoy(value.value)}
                                            value={boy.toString()}
                                            placeholder={i18n.language === "tr" ? "Boy uzunluğunuz" : "Your Height"}
                                        />
                                    :
                                        <Dropdown 
                                            options={imperialHeight} 
                                            onChange={(value) => setBoy(value.value)}
                                            value={conversionHeight(boy)}
                                            placeholder={i18n.language === "tr" ? "Boy uzunluğunuz" : "Your Height"}
                                        />    
                                    }
                                </div>
                                <div className="avatar__inputs--control adct " style={{margin: "2rem 0"}}>
                                    <label htmlFor="kilo" className="avatar__input--label" >
                                    {t('Weight')}
                                    </label>
                                    {/* <input id="kilo" name="kilo" type="number" minLength="2" maxLength="3" required className="avatar__input--input" value={kilo} onChange={(e) => setKilo(e.target.value)}/> */}
                                    {isMetric ?
                                        <Dropdown 
                                            options={metricWeight} 
                                            onChange={(value) => setKilo(value.value)}
                                            value={kilo.toString()}
                                            placeholder={i18n.language === "tr" ? "Kilonuz" : "Your Weight"}
                                        />
                                    :
                                        <Dropdown 
                                            key={(value) => value}
                                            options={imperialWeight} 
                                            onChange={(value) => setKilo(value.value)}
                                            value={conversionWeight(kilo)}
                                            placeholder={i18n.language === "tr" ? "Kilonuz" : "Your Weight"}
                                        />    
                                    }
                                </div>
                               
                              
                                <button type="submit"  className="btn btn-shadow btn-blue btn-smaller" disabled={!boy} onClick={() =>  onClick()}>{t('Continue')}</button>
                                <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/bottomarrow.svg" alt="backbutton" style={{width: "3rem", justifySelf: "center"}} onClick={() => history.push("/mobilegetGender")} />
                  
                               

                            </Form>
                        </Formik>
                        <CSSTransition
                in={showMessage}
                timeout={300}
                classNames="alert"
                unmountOnExit>
                <Alert close={setShowMessage} text={errortext}/>
            </CSSTransition>

               
        
            
            
        </div>
    )
}

export default withNamespaces()(GetSpesificiations)
