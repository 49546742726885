import React, { useEffect, useState }  from 'react';

const Renderobjsfinal  = () => {

    useEffect(() => {
        const scriptx = document.createElement("OBJLoader");
        scriptx.async = true;
        scriptx.src = "app/3dobjscript.js";
        document.body.appendChild(scriptx);
        const script = document.createElement("script");
        script.async = true;
        script.src = "app/3dobjscript.js";
        document.body.appendChild(script);

        
  

    }, [])


    return (<div style={{display: "flex", background: "black"}}>

    



    </div>)
}

export default Renderobjsfinal;