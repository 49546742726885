/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect }  from 'react';
import useState from 'react-usestateref';
import { useHistory } from "react-router-dom";
import Alert from '../GeneralComponents/Alert';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import Webcam from "react-webcam";
import lottie from 'lottie-web';

import ReactAudioPlayer from 'react-audio-player';
import { WebGLInfo } from 'three';
import AlertD from '../GeneralComponents/alert2'
import { withNamespaces } from 'react-i18next';
import { useDeviceOrientation }  from './useDeviceOrientation';


function dataURItoBlob (dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab],{type: mimeString});
}
const AvatarEntry = ({userSex, step, kilo, boy, setResults, useCam, whichCam, t, userKey}) => {

    const { orientation, requestAccess, revokeAccess, mistake } = useDeviceOrientation();
    
    
    const onToggle = (toggleState) => {
        const result = toggleState ? requestAccess() : revokeAccess();
      };

   

    let history = useHistory();
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [avatarOutline, setAvatarOutline] = React.useState("none");
    const [takingPhoto, setTakingPhoto] = React.useState(false)
    const [imgSrc, setImgSrc] = React.useState(null);
    const webcamRef = React.useRef(null);
    const getImgData = React.useRef(null);
    const getPose = React.useRef(null);
    const canvasRef = React.useRef(null);
  
    const [loadingAnimation, setLoadingAnimation] = React.useState("none");
    const [showWebcam, setShowWebcam] = React.useState("block");
    const [isloading, setIsLoading] = React.useState(false);
    const [secondLogoLoading, setSecondLogoLoading] = React.useState("flex")

    const [isAngleTrue, setIsAngleTrue, angleRef] = useState("#9e0505")

    const [x , setX, xref] = useState(80)
    const [counter, setCounter, counterRef] = useState(0);

    const containersecond = React.useRef(null)


    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    } 


    useEffect(() => {
        
        if (orientation) {   
          /*   setX(orientation.beta[0]) */

        if (orientation.beta < 94) {
          /*   setX(95) */
            if (orientation.beta > 80) {
                setIsAngleTrue("#00FF00")
              
                /* if (takingPhoto === false) {
                    startCountdown(true)
                } */
                
            } else {
                if (takingPhoto === false) { 
                    setIsAngleTrue("#9e0505")
   
                    setCounter(0);

                }
            }
            
            
        } else {
            /* if (takingPhoto === true) {
                startCountdown(false)
            } */
            if (takingPhoto === false) { 
                setIsAngleTrue("#9e0505")
                setCounter(0);

            }
        }
    }

    }, [orientation])
    
      
 
    const container = React.useRef(null)
        
    useEffect(() => {
        lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('./data4.json')
        })
        lottie.loadAnimation({
            container: containersecond.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('./data5.json')
            })
        
        onToggle(true)
        document.getElementById('redmp3').play()
        
    }, [])

    
/*     const startCountdown = async (status) => {
        let count = 3;
        const canvasElement = canvasRef.current;
        const ctx = canvasElement.getContext('2d');
        ctx.fillStyle = 'white';
        
    
        if (status === false) {
            await setTakingPhoto(false);
            ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            ctx.fillStyle = 'red';
            count = 0

        } else {
            await setTakingPhoto(true);
            await sleep(2000);
            let refresh = setInterval(() => {
            
          
          ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          ctx.font = '60px Arial';
          ctx.fillText(count, canvasElement.width / 2, canvasElement.height / 2);
          count--;
          if (count === 0) {
            setTakingPhoto(false);
            capture();
           
            ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            clearInterval(refresh);
            
          }
        }, 1000);
        await sleep(3100)
        if(takingPhoto === false) {
           
            clearInterval(refresh)
            ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            ctx.fillStyle = 'red';
            count = 0
        }
    }
      }; */

    const onSubmit = async (imageSrc) => {

        // get me imagenin data valuesi from the input, input a ise pose.js den cikan fotografin datasi veriliyor.
   
        if (imageSrc) { 
          
            
        
        var blob = dataURItoBlob(imageSrc);
            const userInfo = {
                sex: userSex,
                kilo: kilo,
                boy: boy,
                userKey: userKey
            } 
            const formData = new FormData();
            formData.set('userKey', JSON.stringify(userKey));
            formData.set('userinfo', JSON.stringify(userInfo))
            formData.set('camPermission', JSON.stringify(useCam ? useCam : 'false'));
            
            formData.append('photo', blob);
           /*  const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            } */
          
          
            if (counterRef.current < 2) { 
            const postImage = await axios.post("/app/image/upload", formData)
            }
            setCounter(counterRef.current + 1)
         
            if(counterRef.current===2){
               
                setTakingPhoto(true);
                setLoading(true)
                setLoadingAnimation("block");
                setAvatarOutline("none")
                setShowWebcam("none")
                setIsAngleTrue("white")
                setSecondLogoLoading("none")
                
                //loading start
          
                const runPy = await axios.post("/app/image/runpy", userInfo)
                await sleep(3000)
                document.getElementById('greenmp3').src = ''
                if (runPy.data === "") {
                    setError(true)
                   /*  history.push('/getGender?useCam=false')  */

                }
                else { 
                    if (runPy.data.error) {
                        setError(true)
                    } else {
                        await setResults(runPy.data);
                        
         
                        setLoading(false);
                        history.push('/render')
                        //olmadi mobilerender
                    }
                }
                
            }
     }
  

    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
           

        }, 2000)
    }, [])
    const [showMessage, setShowMessage] = useState(false);
    const [errortext, setErrortext] = useState(null);
    const capture = React.useCallback(
        () => {

          const imageSrc = webcamRef.current.getScreenshot();
          onSubmit(imageSrc)
     
        },
        [webcamRef]
      );

     useEffect(() => {
        if (step === '') history.push('/getGender?useCam=false'); 
        setTimeout(() => {
            setAvatarOutline("block")
           

        }, 2000)
        /* const scriptd = document.createElement("script");
        scriptd.async = true;
        scriptd.src = "countdown.js";
        document.body.appendChild(scriptd); */
    
    
        
    }, []); 
    

    
    useEffect(() => {
       
        if (isAngleTrue === '#9e0505') {
            document.getElementById('greenmp3').pause();
            document.getElementById('greenmp3').currentTime = 0;
            setCounter(0);


        } else {
            document.getElementById('greenmp3').play();
            if (counterRef.current === 0) {
             
                getMyPhotoWithDelay(true)

            }
            

        }

    }, [isAngleTrue])

    const checkFrameColor = () => {
        return angleRef.current !== '#9e0505'
    }
    //suraya ayar cekilecek
    const getMyPhotoWithDelay = async (how) => {
        if (how === true) {
            document.getElementById('redmp3').pause();
            if (angleRef.current !== '#9e0505') {
                await sleep(1000)
                if (angleRef.current !== '#9e0505') {
                    await sleep(1000)
                    if (angleRef.current !== '#9e0505') {
                        await sleep(1000)
                        if (angleRef.current !== '#9e0505') {
                            await sleep(1000)
                            if (angleRef.current !== '#9e0505') {
                                await sleep(1000)
                                if (angleRef.current !== '#9e0505') {
                                    await sleep(1000)
                                    if (angleRef.current !== '#9e0505') {
                                        await sleep(1000)
                                        if (angleRef.current !== '#9e0505') {
                                            await sleep(1000)
                                            if (angleRef.current !== '#9e0505') {
                                                await sleep(1000)
                                                if (angleRef.current !== '#9e0505') {
                                                    await sleep(1000)
                                                    if (angleRef.current !== '#9e0505') {
                                                        await sleep(1000)
                                                        if (angleRef.current !== '#9e0505') {
                                                            await sleep(1000)
                                                            if (angleRef.current !== '#9e0505') {
                                                                await sleep(1000)
                                                                if (angleRef.current !== '#9e0505') {
                                                                    await sleep(1000)
                                                                    if (angleRef.current !== '#9e0505') {
                                                                        await sleep(1000)
                                                                        if (angleRef.current !== '#9e0505') {
                                                                            await sleep(1000)
                                                                            if (angleRef.current !== '#9e0505') {
                                                                                await sleep(1000)
                                                                                if (angleRef.current !== '#9e0505') {
                                                                                    capture()
                                                                                    await sleep(1000)
                                                                                    if (angleRef.current !== '#9e0505') {
                                                                                        await sleep(1000)
                                                                                        if (angleRef.current !== '#9e0505') {
                                                                                            await sleep(1000)
                                                                                            if (angleRef.current !== '#9e0505') {
                                                                                                await sleep(1000)
                                                                                                if (angleRef.current !== '#9e0505') {
                                                                                                    await sleep(1000)
                                                                                                    if (angleRef.current !== '#9e0505') {
                                                                                                        await sleep(1000)
                                                                                                        if (angleRef.current !== '#9e0505') {
                                                                                                            await sleep(1000)
                                                                                                            if (angleRef.current !== '#9e0505') capture()
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                
                                                                                            } 
                                                                                        }

                                                                                    }
                                                                                
                                                                                }
                                                        
                                                                            }
                                                        
                                                                        }
                                                        
                                                                    }
                                                        
                                                                }
                                                        
                                                            }
                                                        
                                                        }

                                                    }
                                                    
                                                    
                                                }
                                            }
                                        }
                                        
                                    }

                                }

                            }

                        }

                    }

                }
                

            } 
            
        }


    }
    return (
    
        <div className="landing landing-nojustify cameralan" style={{padding: 0}}>
  
      
                <div style={{padding: "0 !important"}}>
             
                <img src="avataroutline.svg"  alt="avataroutline" className="avataroutline" style={{display: avatarOutline}}/>
    

                <div  className="framesevg" style={{border:`5px solid  ${isAngleTrue}`, display: avatarOutline}}></div>
{/* {                <span style={{position: "absolute", top: "20%", fontSize: "3rem", color: "white", zIndex: 2000}}> {xref.current}</span>
      } */}
                <div className="loadinglogo" ref={container} style={{display: loadingAnimation}}></div>
                <div className="loadinglogo" ref={containersecond} id="logocam" style={{display: secondLogoLoading}}></div>
                    <form encType="multipart/form-data" className="displayflexetc" onSubmit={(e) => { 
                        
                    
                      
                    
                }}>
                    <div className="columns">
                        <div className="column" style={{display: "none"}}>
                            <div className="panel-block">
                                <video className="input_video5"></video>
                               
                                
                            </div>
                        </div>

                    </div>
                    <div className="loading" style={{display: "none"}}>
                    <div className="spinner"></div>
                    </div>
                    <div style={{display: "none"}} className="control5">
                    {loading && <>


</>
}

  </div>
  <input type="text" id="dataimg" ref={getImgData} style={{display: "none"}}/>
  <input type="text" id="datapose" ref={getPose} value={imgSrc}  style={{display: "none"}} />
                   
  {!isloading && <div>
                   <Webcam id="webcam" muted={true}   
                ref={webcamRef}
        screenshotFormat="image/jpeg" 
        style={{padding: "0 !important", display: showWebcam}}
        className="webcammobile"
        videoConstraints={whichCam}
        mirrored={true}
       
       
        onUserMediaError={() => {
                    setErrortext(t("Permission"))
                    setShowMessage(true);

                }}/>
                
                
                <canvas
      id="canvas"
      className="d-none"
      width="300"
      height="400"
      ref={canvasRef}
      style={{position: "absolute", zIndex: 50000, display: avatarOutline}}
    ></canvas>
           </div> }
               {/*  <div style={{position: "absolute"}} ref={container}></div> */}
            {/*    <div className="loadtext" style={{display: loadText, fontSize: "3rem"}}>Kamera Yükleniyor....</div>
                */}
               {!isloading && <button type="submit" id="take-picture" className="btn btn-blue btn-shadow btn-start" style={{display: "none"}}>{t("Capture")}</button>}
                
               </form>
               
                </div>
                
                <ReactAudioPlayer
  src="red.mp3"
  id="redmp3"
                
  style={{visibility: "hidden"}}
/>
<ReactAudioPlayer
  src="green.mp3"
            
  id="greenmp3"
  style={{visibility: "hidden"}}
/>
         
                
            <CSSTransition
                in={error}
                timeout={300}
                classNames="alert"
                unmountOnExit>
                <AlertD />
            </CSSTransition>
      
           
            <CSSTransition
                in={showMessage}
                timeout={300}
                classNames="alert"
                unmountOnExit>
                <Alert close={setShowMessage} text={errortext}/>
            </CSSTransition>
            
        </div>
    );
};

export default withNamespaces()(AvatarEntry);
