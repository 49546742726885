const io = require('socket.io-client/dist/socket.io');

export const connect = (key) => {
	console.log("user key", key)
	const socket = io('https://shopify.sizeandme.com', { query: { key }, transports: ['websocket'], jsonp:false }) ;

	socket.on("connect_error", (err) => {
		console.log(err.message); 
	});
	
	return socket;
};