import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import AvatarResult from "./AvatarResult"
import Footer from './Footer';

import "./last.css"
const style = {
    height: 1000 // we can control scene size by setting container dimensions
};

class App extends Component {
    componentDidMount() {
        this.sceneSetup();
        this.addLights();
        this.loadTheModel();
        this.startAnimationLoop();
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.cancelAnimationFrame(this.requestID);
        this.controls.dispose();
    }

    // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
    // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
    sceneSetup = () => {
        // get container dimensions and use them for scene sizing
        if (window.innerWidth < 900) { 
            const width = 190;
            const height = this.mount.clientHeight;

            this.scene = new THREE.Scene();
            const fov = 2;
            const aspect = 2;  // the canvas default
            const near = 1;
            const far = 100;
            this.camera = new THREE.PerspectiveCamera(fov,  width / height, near, far);
            this.camera.position.set(0, -90, 0);
            this.camera.lookAt(this.scene.position)
            this.camera.up = new THREE.Vector3( 0, 0, 1 );
             
        this.controls = new OrbitControls( this.camera, this.mount );
        // disable zoom out
        this.controls.maxDistance=  90
        //min distance control
        this.controls.minDistance = 80;
        this.controls.enableDamping = false;
	    this.controls.dampingFactor = 0.25;
        this.controls.enableRotate = true;
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.toneMapping = THREE.ReinhardToneMapping;
        this.renderer.toneMappingExposure = 2.3

        this.renderer.setSize( width, height );
        
        this.mount.appendChild( this.renderer.domElement ); // mount using React r
        }
        else if (window.innerWidth < 1400) { 
            const width = 400;
            const height = this.mount.clientHeight;

            this.scene = new THREE.Scene();
            const fov = 2;
            const aspect = 2;  // the canvas default
            const near = 1;
            const far = 100;
            this.camera = new THREE.PerspectiveCamera(fov,  width / height, near, far);
            this.camera.position.set(0, -90, 0);
            this.camera.lookAt(this.scene.position)
            this.camera.up = new THREE.Vector3( 0, 0, 1 );
             
        this.controls = new OrbitControls( this.camera, this.mount );
        // disable zoom out
        this.controls.maxDistance=  90
        //min distance control
        this.controls.minDistance = 80;
        this.controls.enableDamping = false;
	    this.controls.dampingFactor = 0.25;
        this.controls.enableRotate = true;
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.toneMapping = THREE.ReinhardToneMapping;
        this.renderer.toneMappingExposure = 2.3
        this.renderer.setSize( width, height );
        
        this.mount.appendChild( this.renderer.domElement ); // mo
        }
        else {
            const width = 400;
            const height = this.mount.clientHeight;

            this.scene = new THREE.Scene();
            const fov = 2;
            const aspect = 2;  // the canvas default
            const near = 1;
            const far = 100;
            this.camera = new THREE.PerspectiveCamera(fov,  width / height, near, far);
            this.camera.position.set(0, -90, 0);
            this.camera.lookAt(this.scene.position)
            this.camera.up = new THREE.Vector3( 0, 0, 1 );
             
        this.controls = new OrbitControls( this.camera, this.mount );
        // disable zoom out
        this.controls.maxDistance=  90
        //min distance control
        this.controls.minDistance = 60;
        this.controls.enableDamping = false;
	    this.controls.dampingFactor = 0.25;
        this.controls.enableRotate = true;
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.toneMapping = THREE.ReinhardToneMapping;
        this.renderer.toneMappingExposure = 2.3
        this.renderer.setClearColor( 0xffffff, 0);
/*         this.renderer.outputEncoding = THREE.sRGBEncoding; */
/*         this.renderer.shadowMap.enabled = true; */
        this.renderer.setSize( width, height );
        
        this.mount.appendChild( this.renderer.domElement ); // mount using React r

        }
        
        
    

        // OrbitControls allow a camera to orbit around the object
        // https://threejs.org/docs/#examples/controls/OrbitControls
        
   
    };

    // Code below is taken from Three.js OBJ Loader example
    // https://threejs.org/docs/#examples/en/loaders/OBJLoader
    loadTheModel = () => {
        // instantiate a loader
        const loader = new OBJLoader();
        // load a resource
        loader.load(
            // resource URL relative to the /public/index.html of the app
            'result.obj', //aynen
            // called when resource is loaded
            ( object ) => {
                
                object.name = "3dmodel"
                
 
                
                this.scene.add( object );

                // get the newly added object by name specified in the OBJ model (that is Elephant_4 in my case)
                // you can always set console.log(this.scene) and check its children to know the name of a model
                const el = this.scene.getObjectByName("3dmodel"); // objenin isimni gir senin attiginda isim yok o yuzden onu koymadim ona isim koy bozulursa diye
                
                
                // change some custom props of the element: placement, color, rotation, anything that should be
                // done once the model was loaded and ready for display
                console.log(el)
                el.position.set(0, 0, 0 );
                console.log(el.material)
   
          
                el.rotation.y = 0;
                el.rotation.z = 45; 

                // make this element available inside of the whole component to do any animation later
                this.model = el;
            },
            // called when loading is in progresses
             ( xhr ) => {

                const loadingPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                console.log( ( loadingPercentage ) + '% loaded' );

                // update parent react component to display loading percentage
                this.props.onProgress(loadingPercentage);
            },
            // called when loading has errors
             ( error ) => {
                console.log( 'An error happened:' + error );
            }
        );
    };

    // adding some lights to the scene
    addLights = () => {
        const lights = [];
        const light = new THREE.AmbientLight( 0x404040 );
        this.scene.add( light );
   
/*             let lightx = new THREE.SpotLight(0xffffff,4.0, 3000);
  lightx.position.x = 10;
    lightx.castShadow = true;
    this.scene.add( lightx ); */

        // set color and intensity of lights
        lights[ 0 ] = new THREE.PointLight( 0xffffff, 1, 0 );
        lights[ 1 ] = new THREE.PointLight( 0xffffff, 1, 0 );
        lights[ 2 ] = new THREE.PointLight( 0xffffff, 1, 0 );

        // place some lights around the scene for best looks and feel
        lights[ 0 ].position.set( 0, 2000, 0 );
        lights[ 1 ].position.set( 2000, 2000, 500 );
        lights[ 2 ].position.set( - 1000, - 2000, - 1000 );

        this.scene.add( lights[ 0 ] );
        this.scene.add( lights[ 1 ] );
        this.scene.add( lights[ 2 ] );
    };
    
    startAnimationLoop = () => {
        // slowly rotate an object
        if (this.model) this.model.rotation.z += 0.003;

        this.renderer.render( this.scene, this.camera );

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
    };


    handleWindowResize = () => {
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        this.renderer.setSize( width, height );
        this.camera.aspect = width / height;

        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        this.camera.updateProjectionMatrix();
    };

    render() {
        return <div style={style} ref={ref => (this.mount = ref)} />;
    }
}

class Container extends React.Component {
    state = {isMounted: true, showPopup: false};
    
    render() {
        const {isMounted = true, loadingPercentage = 0, showPopup} = this.state;
  
        
        const {results, boy, kilo, display, setDisplay} = this.props;
        
  

        return (
     <div className="lastPage">
          <AvatarResult results={results} boy={boy} kilo={kilo} display={display} setDisplay={setDisplay} />
           
            <div className="showobj">
              {/*   <button onClick={() => this.setState(state => ({isMounted: !state.isMounted}))}>
                    {isMounted ? "Unmount" : "Mount"}
                </button> */}
                {isMounted && <App onProgress={loadingPercentage => this.setState({ loadingPercentage })} />}
{/*                 {isMounted && loadingPercentage !== 100 && <div>Loading Model: {loadingPercentage}%</div>} */}
         {/*        <div className="vucutolcugosterdiv">
                
                    <a href="/getGender" className="btn btn-shadow btn-blue btn-smaller">Geri Dön</a>
                </div> */}
            <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg" className="lastpagelogo"/>
              <img src="asd.svg" className="finalbackground"/>
               {/*  <button className="btn btn-shadow btn-blue btn-smaller" id='mobilebuttonfast'  style={{borderRadius: "10px"}} onClick={() => display === "flex" ? setDisplay("none") : setDisplay("flex")}>Vücut Ölçülerimi Göster</button> */}
                
                
            </div>
             <Footer />
           
            </div>
          
        )
    }
}
export default Container;
