import React, { useEffect } from 'react';

import { useHistory } from "react-router-dom";

import lottie from 'lottie-web';
import { withNamespaces } from 'react-i18next';


const AlertD = ({t}) => {
    const history = useHistory();
    const containererror = React.useRef();

    useEffect(() => {
        lottie.loadAnimation({
            container: containererror.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('./data6.json')
        })
     
        
    }, [])


    return (
        <div className='alert'>
            <div className="alert__content">
         
                <div className="erroranimation" ref={containererror}></div>
                <div className="alert__textarea">
                    <p className="aciklamafirst" style={{marginBottom: "2rem"}}>
                        {t("PhotoError")}
                    </p>
                </div>
                <div className="loginContainer__main-input-control">
                    <button type="button" className="btn btn-blue btn-shadow btn-start loginContainer__main--input-submit" onClick={() => {
                                        /* window.location.href = "https://sizeandme.com/app/getGender" */
                                        window.location.replace("https://sizeandme.com/app/getGender?useCam=false");
                    }}>{t("TryAgain")}</button>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(AlertD);