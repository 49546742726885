import React from 'react';
import { useHistory } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

const Alert = ({text, close, t}) => {

    return (
        <div className='alert'>
            <div className="alert__content">
            
                <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/alert.svg"  width="150px"/>
                <div className="alert__textarea" style={{marginTop: "1rem"}}>
                    <p className="aciklamafirst" style={{marginBottom: "2rem"}}>
                        {text ? text : t("ReachServer")}
                    </p>
                </div>
                <div className="loginContainer__main-input-control">
                    <button type="button" className="loginContainer__main--input-submit" onClick={() => {
                                        /* window.location.href = "https://sizeandme.com/app/getGender" */
                                        window.location.replace("https://sizeandme.com/app/getGender?useCam=false");
                    }}>{t("TryAgain")}</button>
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(Alert);