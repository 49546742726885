/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

  import { CookiesProvider } from 'react-cookie';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
import LanguageSelector from '.././GeneralComponents/LanguageSelector';


  
const queryString = require('query-string');

const GetGender = ({setuserSex, setStep, setUseCam, t, setUserKey, setBrand}) => {
    const history = useHistory();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      }
    
    useEffect(() =>  {
        const parsed = queryString.parse(location.search);
        setUseCam(parsed.useCam);
        setUserKey(parsed.key);
        setBrand(parsed.brand);
    }, [])

    return (
        <div className="landing landing-nojustify landingmobile">
            <LanguageSelector />
            
            <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg"  alt="sizemelogo" style={{marginBottom: "3rem"}} width="250px"/>
          
            <div className="div__flex-buttons" style={{width: "40rem"}}>
                <span className="getgenderil" alt="women" onClick={() => {
                setuserSex('Kadın');
                setStep("1")
                history.push("/mobilegetSpesifications")
                }}>{t('Woman')}</span>
                <span className="getgenderil"  alt="men" onClick={() => {
                setuserSex('Erkek');
                setStep("1")
                history.push("/mobilegetSpesifications")
            }}>{t('Man')}</span>
            </div>

           {/*  <div style={{width: "40rem", textAlign: "center"}} >
                <span className="aciklamafirst"> {t('AcıklamaFirst')} </span>

            </div> */}
     
        </div>
    )
}

export default withNamespaces()(GetGender);