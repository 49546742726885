import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './sass/main.scss';



import Routes from './Routes';

//Components



const App = () => {

  return (
    
    <Router>
    <>
      <Routes />
    </>
    </Router>
  );
};
export default App;