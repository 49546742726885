/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

  
  import { useCookies } from 'react-cookie';
  import { withNamespaces } from 'react-i18next';
  import i18n from '../i18n';
import LanguageSelector from './GeneralComponents/LanguageSelector';
import AlertD from './GeneralComponents/OpenMobile';
const queryString = require('query-string');

const GetGender = ({setuserSex, setStep, setUseCam, t, setBrand, setUserKey}) => {
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['name']);
    const[modal, showModal] = useState(true);

    useEffect(() =>  {
        const parsed = queryString.parse(location.search);
        setUseCam(parsed.useCam);
        setUserKey(parsed.key);
        setBrand(parsed.brand);
        if (isMobile) {
            history.push(`/mobilegetGender?useCam=false`)
        } 
    }, [])
    
    return (
        <div className="landing landing-nojustify">
            
            <div className="bg"></div>
            <div className="bg bg2"></div>
            <div className="bg bg3"></div>
            <LanguageSelector />
       
           
            
            <div className="avatarsteps--wrapper" style={{display: "flex", flexDirection: "column"}}>
            
                <div className="avatarsteps">
                <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg"  alt="sizemelogo" style={{marginBottom: "2rem"}} width="250px"/>
                    <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/indir.png" alt="qrcodesizeme"/>
    
            
        </div>
        </div>
        <CSSTransition
                in={modal}
                timeout={300}
                classNames="alert"
                unmountOnExit>
                <AlertD close={showModal}/>
            </CSSTransition>
        </div>
    )
}

export default withNamespaces()(GetGender);